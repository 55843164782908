import React, { useCallback, useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { Alert, AlertTitle, Box, CircularProgress, FormControl, Grid, IconButton, InputLabel, Select, MenuItem, Tooltip, Typography, useTheme, TextField, Button, Checkbox } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { v4 as uuidv4 } from "uuid";

import { tokens } from "../../theme";
import { ARTIFICIAL_INTELLIGENCE, MARKET_INDENTED_LIST } from "../../utilities/constants";
import { DataGrid } from "@mui/x-data-grid";
import { useSelector } from "react-redux";
import { getLocalDateTime, getUTCDateTime } from "../../utilities/helpers";
import ModalDialog from "../../components/ModalDialog";
import codeTablePostgresApi from "../../apis/codeTablePostgresApi";
import { EqipItem } from "../../components/EqipItem";
import ieqipCoreServiceApi from "../../apis/ieqipCoreServiceApi";

const OPENAI_ASSISTANT_NAME = process.env.REACT_APP_AI_ASSISTANT;

const AIv2 = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [uploadedFiles, setUploadFiles] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [aiPipeconeDocuments, setAiPipeconeDocuments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [markets, setMarkets] = useState([]);
  const [selectedMarkets, setSelectedMarkets] = useState([]);
  const [commodities, setCommodities] = useState([]);
  const [selectedcommodities, setSelectedCommodities] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deletingAiPipeconeDocument, setDeletingAiPipeconeDocument] = useState(null);
  const [page, setPage] = useState(0);
  const [pageSize] = useState(50);
  const [totalCount, setTotalCount] = useState(100);
  const [searchText, setSearchText] = useState(null);

  const idToken = useSelector((state) => {
    return state.token;
  });

  const allowedCommodities = useSelector((state) => {
    return state.allowedCommodities;
  });

  const allowedMarkets = useSelector((state) => {
    return state.allowedMarkets;
  });

  const requestHeader = {
    headers: {
      Authorization: idToken,
    },
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function fetchData() {
    setIsLoading(true);

    let url = `/api/aiassistantdocumentswithpaging?page=${page + 1}&page_size=${pageSize}`;
    if (searchText) {
      url += `&file_name=${searchText}`;
    }
    if (selectedcommodities?.length > 0) {
      url += `&commodity=${selectedcommodities[0]}`;
    }
    if (selectedMarkets?.length > 0) {
      url += `&market=${selectedMarkets[0]}`;
    }

    const [responseCodeTable, responseDocumentApi] = await Promise.all([codeTablePostgresApi.get(`/codetables/codetabletypes/12,13`), 
      ieqipCoreServiceApi.get(url, requestHeader)]);

    setAiPipeconeDocuments(responseDocumentApi?.data?.documents);
    setTotalCount(responseDocumentApi.data.total_count);
    const codeTables = responseCodeTable.data.body;
    setCommodities(codeTables.filter((codeTable) => codeTable.code_table_type_id === 12 && allowedCommodities.includes(codeTable.code)));
    const sortedMarkets = codeTables.filter((codeTable) => codeTable.code_table_type_id === 13 && allowedMarkets.includes(codeTable.code))?.sort((a, b) => a.sort_order - b.sort_order);
    setMarkets(sortedMarkets);
    setIsLoading(false);
  }

  const loggedInUser = useSelector((state) => {
    return state.user;
  });

  const onDrop = useCallback(
    async (acceptedFiles) => {
      try {
        setIsLoading(true);
        setErrorMessage(null);
        if (acceptedFiles?.length > ARTIFICIAL_INTELLIGENCE.MAX_FILE_UPLOAD_TO_PIPECONE) {
          setErrorMessage(`Please upload up to ${ARTIFICIAL_INTELLIGENCE.MAX_FILE_UPLOAD_TO_PIPECONE} files`);
        } else if (selectedcommodities?.length <= 0) {
          setErrorMessage(`Please select one or more Commondities`);
        } else if (selectedMarkets?.length <= 0) {
          setErrorMessage(`Please select one or more Markets`);
        } else {
          setUploadFiles(acceptedFiles);

          const bucketName = process.env.REACT_APP_S3_IEQIP_AI_DOCUMENT;
          const vectorStoreName = `${selectedcommodities[0]}_${selectedMarkets[0]}_${process.env.REACT_APP_ENVIRONMENT}`;
          // iterate each file, upload file to vector store, s3 bucket, insert documents to database
          await Promise.all(acceptedFiles.map(async (acceptedFile) => {
            
            // Step 1. Check file if exist
            try {
              let response = await ieqipCoreServiceApi.get(`/api/aiassistantdocuments/commodity/${selectedcommodities[0]}/market/${selectedMarkets[0]}/filename/${acceptedFile.name}`);
              const uploaded_filename = response.data.uploaded_filename;
              if (uploaded_filename) {
                acceptedFile.isFailed = true;
                acceptedFile.error = `File ${acceptedFile.name} already exists`;
                return acceptedFile;
              }
            } catch (error) {
                if (error.response?.data?.detail !== "404: AI Assistant document to not found"){
                  acceptedFile.isFailed = true;
                  acceptedFile.error = `Failed to find file in database. ${error}`;
                  return acceptedFile;
                }
            }

            // Step 2. Upload file to OpenAI assistant Vector Store
            let formData = new FormData();
            formData.append("files", acceptedFile);
            formData.append("assistant_name", OPENAI_ASSISTANT_NAME);
            formData.append("vector_store_name", vectorStoreName);
  
            let uploadedFilesToVectorStore;
  
            try {
              uploadedFilesToVectorStore = await ieqipCoreServiceApi.post("/api/openai/assistant/v2/uploadfiles", formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              });
            } catch (error) {
              console.error('set acceptedFiles', acceptedFiles);
              acceptedFile.isFailed = true;
              acceptedFile.error = `Failed upload file to vector store. ${error}`;
              return acceptedFile;
            }

            // Step 3: Upload files to s3 bucket
            formData = new FormData();
            formData.append("file", acceptedFile);
            let responseUploadedFile;
          
            try {
              responseUploadedFile = await ieqipCoreServiceApi.post(
                `/api/s3/v2/upload?bucket_name=${bucketName}&folder=${vectorStoreName}`, 
                formData,
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                }
              );
              acceptedFile.isSucceed = true;
            } catch (error) {
              acceptedFile.isFailed = true;
              acceptedFile.error = `Failed upload file to S3. ${error}`;
              console.error(error);
            }
          
            // Step 4: insert documents to database
            if (acceptedFile.isSucceed) {
              const foundUploadedFile = uploadedFilesToVectorStore?.data?.documents?.filter(
                (uploadedFile) => uploadedFile.original_filename === acceptedFile.name
              )[0];
              
              const newDocument = {
                ai_assistant_document_uid: uuidv4(),
                original_filename: acceptedFile.name,
                uploaded_filename: responseUploadedFile?.data?.uploaded_filename,
                commodity: selectedcommodities[0],
                market: selectedMarkets[0], 
                vector_store_file_id: foundUploadedFile.vector_store_file_id,
                is_active: true,
                created_by: loggedInUser.email,
                created_date: getUTCDateTime(),
              };
          
              try {
                await ieqipCoreServiceApi.post("/api/aiassistantdocuments", newDocument);
              } catch (error) {
                acceptedFile.isFailed = true;
                acceptedFile.error = `Failed insert document to database. ${error}`;;
                console.error(error);
              }
            }
            return acceptedFile;
          }));

          setUploadFiles(acceptedFiles);
        }
      } catch (error) {
        console.error("onDrop.error", error);
      } finally {
        setIsLoading(false);
        
        await fetchData();
      }
    },
    // eslint-disable-next-line
    [loggedInUser.email, selectedcommodities, selectedMarkets]
  );

  const handleCommodityChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedCommodities(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleMarketChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedMarkets(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleDeleteOk = async () => {
    setIsLoading(true);
    setErrorMessage(null);
    try {
      setIsDeleting(true);

      // step 1: delete file from vector store
      const vectorStoreName = `${deletingAiPipeconeDocument.commodity}_${deletingAiPipeconeDocument.market}_${process.env.REACT_APP_ENVIRONMENT}`;
      const deleteDcument = {
        assistant_name: OPENAI_ASSISTANT_NAME,
        vector_store_name: vectorStoreName,
        vector_store_file_id: deletingAiPipeconeDocument.vector_store_file_id,
      };

      try {
        await ieqipCoreServiceApi.post(`/api/openai/assistant/v2/delete`, deleteDcument);
      } catch (error) {
        console.error(error);
      }

      // step 2: delete document from database
      await ieqipCoreServiceApi.delete(`/api/aiassistantdocuments/${deletingAiPipeconeDocument.ai_assistant_document_uid}`);

      // step 3: delete document from S3
      const bucketName = process.env.REACT_APP_S3_IEQIP_AI_DOCUMENT;
      const folder = `${deletingAiPipeconeDocument.commodity}_${deletingAiPipeconeDocument.market}_${process.env.REACT_APP_ENVIRONMENT}`;
      await ieqipCoreServiceApi.delete(`/api/s3/v2/delete/${deletingAiPipeconeDocument.uploaded_filename}?bucket_name=${bucketName}&folder=${folder}`);

      // step 4: reload data
      await fetchData();
    } catch (error) {
      console.error("handleDeleteOk", error);
      setErrorMessage(`Unable to delete AI document. ${error.response?.data}`);
    } finally {
      setIsDeleting(false);
      setOpenDeleteModal(false);
      setIsLoading(false);
    }
  };

  const handleDeleteCancel = () => {
    setOpenDeleteModal(false);
  };

  const handleDelete = (aiAssistantDocument) => {
    setOpenDeleteModal(true);
    setDeletingAiPipeconeDocument(aiAssistantDocument);
  };

  const handleDonwload = async (aiAssistantDocument) => {
    try {
      const bucketName = process.env.REACT_APP_S3_IEQIP_AI_DOCUMENT;
      const folder = `${aiAssistantDocument.commodity}_${aiAssistantDocument.market}`;
      const response = await ieqipCoreServiceApi.get(`/api/s3/v2/download/${bucketName}/${folder}/${aiAssistantDocument.uploaded_filename}`, {
        responseType: "blob",
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", aiAssistantDocument.original_filename || aiAssistantDocument.uploaded_filename);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Download failed", error);
    } finally {
    }
  };

  const handleCloseUploadFile = (name) => {
    setUploadFiles(uploadedFiles.filter((uploadFile) => uploadFile.name !== name));
  };

  const handleSearchText = async () => {
    try {
      setIsLoading(true);
      let url = `/api/aiassistantdocumentswithpaging?page=${page + 1}&page_size=${pageSize}`;
      if (searchText) {
        url += `&file_name=${searchText}`;
      }
      if (selectedcommodities?.length > 0) {
        url += `&commodity=${selectedcommodities[0]}`;
      }
      if (selectedMarkets?.length > 0) {
        url += `&market=${selectedMarkets[0]}`;
      }
      const responseDocumentApi = await ieqipCoreServiceApi.get(url);
      setAiPipeconeDocuments(responseDocumentApi.data.documents);
      setTotalCount(responseDocumentApi.data.total_count);
    } catch (error) {
      console.error("handleSearchText", error);
    } finally {
      setIsLoading(false);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  const columns = [
    {
      field: "original_filename",
      headerName: "File Name",
      flex: 1,
      width: 150,
      renderCell: ({ row }) => {
        return (
          <>
            <Tooltip title={row.original_filename}>{row.original_filename}</Tooltip>
          </>
        );
      },
    },
    {
      field: "commodity",
      headerName: "Commodity",
      flex: 1,
      width: 50,
    },
    {
      field: "market",
      headerName: "Market",
      flex: 1,
      width: 50,
    },
    {
      field: "created_by",
      headerName: "Created By",
      flex: 1,
      width: 50,
    },
    {
      field: "created_date",
      headerName: "Created Date",
      flex: 1,
      width: 50,
      renderCell: ({ row }) => {
        return <>{getLocalDateTime(row.created_date)}</>;
      },
    },
    {
      field: "ai_pipecone_document_uid",
      headerName: "Actions",
      flex: 1,
      width: 50,
      renderCell: ({ row }) => {
        return (
          <Box>
            <IconButton color="success" onClick={() => handleDonwload(row)}>
              <FileDownloadIcon />
            </IconButton>
            <IconButton color="error" onClick={() => handleDelete(row)}>
              <DeleteIcon />
            </IconButton>
          </Box>
        );
      },
    },
  ];
  return (
    <Box m="20px">
      {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
      <Box>
        <FormControl sx={{ minWidth: 300, marginTop: 1, marginBottom: 1, marginRight: 1 }}>
          <InputLabel id="commodity-label">Commodity</InputLabel>
          <Select fullWidth labelId="commodity-label" label="Commodity" value={selectedcommodities} onChange={handleCommodityChange} renderValue={(selected) => selected.join(", ")}>
            {commodities.map((option) => (
              <MenuItem value={option.code}>
                <Checkbox checked={selectedcommodities.indexOf(option.code) > -1} />
                {`${option.description}`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ minWidth: 300, marginTop: 1, marginBottom: 1 }}>
          <InputLabel id="market-label">Market</InputLabel>
          <Select fullWidth labelId="market-label" label="Market" value={selectedMarkets} onChange={handleMarketChange} renderValue={(selected) => selected.join(", ")}>
            {markets.map((option) => (
              <MenuItem value={option.code}>
                {MARKET_INDENTED_LIST.includes(option.code) && <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>}
                <Checkbox checked={selectedMarkets.indexOf(option.code) > -1} />
                {`${option.description}`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box>
        <FormControl fullWidth sx={{ marginTop: 1, marginBottom: 1 }}>
          <Grid container spacing={1}>
            <Grid item xs={11}>
              <EqipItem>
                <TextField fullWidth label="Enter file name" value={searchText} onChange={(e) => setSearchText(e.target.value)} />
              </EqipItem>
            </Grid>
            <Grid item xs={1}>
              <Button variant="contained" onClick={handleSearchText}>
                Search
              </Button>
            </Grid>
          </Grid>
        </FormControl>
      </Box>
      <Box>
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <Box
            sx={{
              p: 2,
              border: "1px dashed grey",
              textAlign: "center",
            }}
          >
            {isDragActive ? (
              <Typography>Drop the files here ...</Typography>
            ) : (
              <Typography>
                Drag 'n' drop some files here, or click to select files. Please upload <b>pdf</b> file only
              </Typography>
            )}
          </Box>
        </div>
      </Box>
      <Box sx={{ marginTop: 1 }}>
        <Grid container spacing={1}>
          {uploadedFiles?.length > 0 &&
            uploadedFiles.map((uploadFile) => {
              return (
                <Grid item xs={6}>
                  {!uploadFile.isFailed && !uploadFile.isSucceed && (
                    <Alert severity="info">
                      <AlertTitle>{uploadFile.name}</AlertTitle>
                      <CircularProgress size={30} />
                    </Alert>
                  )}
                  {uploadFile.isSucceed && (
                    <Alert
                      severity="success"
                      action={
                        <IconButton onClick={() => handleCloseUploadFile(uploadFile.name)}>
                          <CloseIcon />
                        </IconButton>
                      }
                    >
                      <AlertTitle>{uploadFile.name}</AlertTitle>
                      <strong>Upload successful!</strong>
                    </Alert>
                  )}
                  {uploadFile.isFailed && (
                    <Alert
                      severity="error"
                      action={
                        <IconButton onClick={() => handleCloseUploadFile(uploadFile.name)}>
                          <CloseIcon />
                        </IconButton>
                      }
                    >
                      <AlertTitle>{uploadFile.name}</AlertTitle>
                      <strong>{uploadFile.error}</strong>
                    </Alert>
                  )}
                </Grid>
              );
            })}
        </Grid>
      </Box>
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-columnSeparator": {
            display: "none",
          },
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.blueAccent[600],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.grey[900],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.grey[900],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.grey[500]} !important`,
          },
        }}
      >
        {/* https://v4.mui.com/components/data-grid/pagination/ */}
        <DataGrid rows={aiPipeconeDocuments || []} columns={columns} loading={isLoading} pagination pageSize={pageSize} rowsPerPageOptions={[pageSize]} rowCount={totalCount} paginationMode="server" onPageChange={(newPage) => setPage(newPage)} />
      </Box>
      <ModalDialog title="Delete" content="Are you sure you want to delete this item?" isLoading={isDeleting} open={openDeleteModal} handleOk={handleDeleteOk} handleCancel={handleDeleteCancel} />
    </Box>
  );
};

export default AIv2;
