import * as React from "react";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Box, Paper, InputBase, IconButton, CircularProgress, FormControl, InputLabel, Select, MenuItem, Checkbox, Drawer, Typography, Chip, Link, Alert } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import CloseIcon from "@mui/icons-material/Close";
import codeTablePostgresApi from "../../apis/codeTablePostgresApi";
import { MARKET_INDENTED_LIST } from "../../utilities/constants";
import ieqipCoreServiceApi from "../../apis/ieqipCoreServiceApi";
import { convertMessageToHtml } from "../../utilities/helpers";

export default function AskMeDialogV2({ open, setOpen }) {
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [summaries, setSummaries] = useState([]);
  const [commodities, setCommodities] = useState([]);
  const [selectedCommodities, setSelectedCommodities] = useState([]);
  const [markets, setMarkets] = useState([]);
  const [selectedMarkets, setSelectedMarkets] = useState([]);

  const allowedCommodities = useSelector((state) => {
    return state.allowedCommodities;
  });

  const allowedMarkets = useSelector((state) => {
    return state.allowedMarkets;
  });

  const controlWidth = 600;

  const messageStyles = {
    pre: {
      backgroundColor: "#f5f5f5",
      padding: "12px",
      borderRadius: "4px",
      overflowX: "auto",
    },
    code: {
      fontFamily: "monospace",
    },
    strong: {
      fontWeight: "bold",
    },
    em: {
      fontStyle: "italic",
    },
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [allowedCommodities, allowedMarkets]);

  async function fetchData() {
    const [responseCodeTable] = await Promise.all([codeTablePostgresApi.get(`/codetables/codetabletypes/12,13`)]);
    const codeTables = responseCodeTable.data.body;
    setCommodities(codeTables.filter((codeTable) => codeTable.code_table_type_id === 12 && allowedCommodities.includes(codeTable.code)));
    const sortedMarkets = codeTables.filter((codeTable) => codeTable.code_table_type_id === 13 && allowedMarkets.includes(codeTable.code))?.sort((a, b) => a.sort_order - b.sort_order);
    setMarkets(sortedMarkets);
  }

  const onHandleSend = async (e) => {
    e.preventDefault();
    setSummaries([]);

    try {
      if (!title) return;
      setErrorMessage(null);
      setLoading(true);
      // Process each combination sequentially
      for (const commodity of selectedCommodities) {
        for (const market of selectedMarkets) {
          const namespace = `${commodity}_${market}_${process.env.REACT_APP_ENVIRONMENT}`;

          try {
            const askPayload = {
              question: title,
              assistant_name: process.env.REACT_APP_AI_ASSISTANT,
              vector_store_names: [namespace],
            };

            const responseMessage = await ieqipCoreServiceApi.post("/api/openai/assistant/v2/ask", askPayload);

            if (responseMessage) {
              const message = responseMessage?.data?.message;
              // Update summaries one by one
              setSummaries((prevSummaries) => [
                ...prevSummaries,
                {
                  commodity,
                  market,
                  message,
                  citations: responseMessage?.data?.citations,
                },
              ]);
            }
          } catch (error) {
            console.error(`Error processing ${commodity}/${market}:`, error);
            setErrorMessage(`Error processing ${commodity}/${market}: ${error?.response?.data?.detail}`);
          }
        }
      }
    } catch (error) {
      console.error("General error:", error);
      setErrorMessage(`General error: ${error}`);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const handleCommodityChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedCommodities(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleMarketChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedMarkets(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleDonwload = async (commodity, market, original_filename) => {
    try {
      let response = await ieqipCoreServiceApi.get(`/api/aiassistantdocuments/commodity/${commodity}/market/${market}/filename/${original_filename}`);
      const uploaded_filename = response.data.uploaded_filename;
      console.log('uploaded_filename', uploaded_filename)

      const bucketName = process.env.REACT_APP_S3_IEQIP_AI_DOCUMENT;
      const folder = `${commodity}_${market}_${process.env.REACT_APP_ENVIRONMENT}`;
      response = await ieqipCoreServiceApi.get(`/api/s3/v2/download/${bucketName}/${folder}/${uploaded_filename}`, {
        responseType: "blob",
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", original_filename || uploaded_filename);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Download failed", error);
    } finally {
    }
  };

  return (
    <div>
      <Drawer anchor="right" open={open} onClose={handleClose}>
        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
        <Box sx={{ display: "flex", justifyContent: "flex-end", p: 1 }}>
          <IconButton aria-label="delete" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box sx={{ marginLeft: 1, marginRight: 1, width: { controlWidth } }}>
          <FormControl sx={{ minWidth: 300, marginTop: 1, marginBottom: 1, marginRight: 1 }}>
            <InputLabel id="commodity-label">Commodity</InputLabel>
            {Array.isArray(commodities) && (
              <Select
                multiple
                fullWidth
                labelId="commodity-label"
                label="Commodity"
                value={selectedCommodities || []}
                onChange={handleCommodityChange}
                renderValue={(selected) => {
                  const result = Array.isArray(selected) ? selected.join(", ") : [...selected];
                  return result;
                }}
              >
                {commodities.map((option) => (
                  <MenuItem value={option.code}>
                    <Checkbox checked={selectedCommodities?.indexOf(option.code) > -1} />
                    {`${option.description}`}
                  </MenuItem>
                ))}
              </Select>
            )}
          </FormControl>
          <FormControl sx={{ minWidth: 300, marginTop: 1, marginBottom: 1 }}>
            <InputLabel id="market-label">Market</InputLabel>
            {Array.isArray(markets) && (
              <Select
                multiple
                fullWidth
                labelId="market-label"
                label="Market"
                value={selectedMarkets || []}
                onChange={handleMarketChange}
                renderValue={(selected) => {
                  const result = Array.isArray(selected) ? selected.join(", ") : [...selected];
                  return result;
                }}
              >
                {markets.map((option) => (
                  <MenuItem value={option.code}>
                    {MARKET_INDENTED_LIST.includes(option.code) && <div>&nbsp;&nbsp;&nbsp;</div>}
                    <Checkbox checked={selectedMarkets?.indexOf(option.code) > -1} />
                    {`${option.description}`}
                  </MenuItem>
                ))}
              </Select>
            )}
          </FormControl>

          <Paper component="form" elevation={3} sx={{ p: "2px 4px", display: "flex", alignItems: "center" }}>
            <InputBase sx={{ ml: 1, flex: 1 }} color="info" placeholder="Send a message..." inputProps={{ "aria-label": "Send a message..." }} value={title} onChange={(e) => setTitle(e.target.value)} />
            <IconButton type="submit" color="primary" sx={{ p: "10px" }} aria-label="search" onClick={onHandleSend}>
              {loading ? <CircularProgress size={23} /> : <SendIcon color="primary" />}
            </IconButton>
          </Paper>
          {summaries?.map((summary) => {
            const caption = `${summary.commodity} - ${summary.market}`;
            return (
              <Box sx={{ maxWidth: 600, marginTop: 1, marginBottom: 1 }}>
                <Typography variant="h4"></Typography>
                <Chip label={caption} color="primary" />
                {summary && summary.message && (
                  <Box sx={{ marginTop: 1 }}>
                    <Paper>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: convertMessageToHtml(summary.message),
                        }}
                        style={{
                          padding: "16px",
                          lineHeight: "1.5",
                          "& pre": messageStyles.pre,
                          "& code": messageStyles.code,
                          "& strong": messageStyles.strong,
                          "& em": messageStyles.em,
                        }}
                      />
                    </Paper>
                  </Box>
                )}
                {summary && summary.citations && (
                  <Box sx={{ marginTop: 1 }}>
                    <Paper>
                      <Typography variant="h5" style={{paddingTop: "10px", paddingLeft: "10px"}}>Citations:</Typography>
                      <div style={{
                        padding: "16px",
                        lineHeight: "1.5",
                      }}>
                        {summary.citations.split('\n').map((citation, index) => (
                          <div key={index}>
                            <Link href="#" onClick={() => handleDonwload(summary.commodity, summary.market, citation)}>[{index + 1}] {citation}</Link>
                          </div>
                        ))}
                      </div>
                    </Paper>
                  </Box>
                )}
              </Box>
            );
          })}
        </Box>
      </Drawer>
    </div>
  );
}

