import { useState } from "react";
import { Box, IconButton, useTheme, Menu, MenuItem, Tooltip } from "@mui/material";
import { useContext } from "react";
import { useDispatch } from "react-redux";
import { ColorModeContext, tokens } from "../../theme";
// import InputBase from "@mui/material/InputBase";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
// import SearchIcon from "@mui/icons-material/Search";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { setLogout } from "../../state";
import AskMeDialog from "../ai/AskMeDialog";
// import AskMeDialogV2 from "../aiv2/AskMeDialogV2";
import AskMeDialogV3 from "../aiv2/AskMeDialogV3";

const Topbar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const [openAskMe, setOpenAskMe] = useState(false);
  const [openieQipCopilot, setOpenieQipCopilot] = useState(false);

  const isOpen = Boolean(anchorEl);
  
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => {
    setAnchorEl(null);
    dispatch(setLogout());
  };

  const handleAskMeClick = (event) => {
    setOpenAskMe(true);
  }

  const handleiEqipCopilotClick = (event) => {
    setOpenieQipCopilot(true);
  }

  return (
    <Box display="flex" justifyContent="space-between" p={2}>
      {/* SEARCH BAR */}
      <Box
        display="flex"
        backgroundColor={colors.primary[400]}
        borderRadius="3px"
      >
        {/* <InputBase sx={{ ml: 2, flex: 1 }} placeholder="Search" />
        <IconButton type="button" sx={{ p: 1 }}>
          <SearchIcon />
        </IconButton> */}
      </Box>

      {/* ICONS */}
      <Box display="flex">
        <Tooltip title={theme.palette.mode === "dark" ? "Light Mode" : "Dark Mode"}>
          <IconButton onClick={colorMode.toggleColorMode}>
            {theme.palette.mode === "dark" ? (
              <DarkModeOutlinedIcon />
            ) : (
              <LightModeOutlinedIcon />
            )}
          </IconButton>
        </Tooltip>
        {/* <IconButton>
          <NotificationsOutlinedIcon />
        </IconButton> */}
        <Tooltip title="Ask ieQip">
          <IconButton>
            <HelpOutlineIcon onClick={handleAskMeClick}/>
          </IconButton>
        </Tooltip>
        <Tooltip title="ieQip Copilot">
          <IconButton color="primary">
            <AutoAwesomeIcon onClick={handleiEqipCopilotClick}/>
          </IconButton>
        </Tooltip>
        <IconButton>
          <PersonOutlinedIcon onClick={handleClick}/>
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={isOpen}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <MenuItem onClick={handleClose}>Log Out</MenuItem>
        </Menu>
        <AskMeDialog open={openAskMe} setOpen={setOpenAskMe}/>
        <AskMeDialogV3 open={openieQipCopilot} setOpen={setOpenieQipCopilot}/>
      </Box>
    </Box>
  );
};

export default Topbar;
